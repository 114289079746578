<template>
  <dialog-form-list
    :config="config"
    :formData="formData"
    :title="title"
    @cancel="cancel"
    @confirm="confirm"
  />
</template>

<script>
export default {
  props: {
    // 当前选中的树节点
    treeRow: {
      type: Object,
      default: () => null,
    },
    // 是否是添加一级分组
    isFirstGroup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const cmd = this.$store.state.cmd
    const title = this.$t(`base.${cmd}`)
    return {
      cmd,
      title,
      formData: {
        scope: 'COMPANY',
      },
      config: [
        // lang:部门名称
        {
          label: this.$t('userGroup.name'),
          prop: 'name',
          rules: {
            noNull: true,
          },
        },
        // lang:上级部门
        {
          label: this.$t('userGroup.topName'),
          prop: 'topName',
          hidden: true,
          rules: {
            noNull: true,
          },
          tagProps: {
            disabled: true,
          },
        },
        // 所属层级
        {
          tag: 'el-select',
          label: this.$t('userGroup.level'),
          prop: 'level',
          rules: {
            noNull: true,
          },
          tagProps: {
            options: this.$dict.levelEnum(),
            disabled: true,
          },
        },
      ],
    }
  },
  mounted() {
    this.setDefaultValue()
  },
  methods: {
    // 设置默认值
    setDefaultValue() {
      this.$nextTick(() => {
        //默认值
        if (this.cmd !== 'add') {
          this.$util.setDefaultValue(this.formData, this.treeRow)
          if (this.treeRow.level !== 'ONE') {
            this.$ajax({
              url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.userAccountGroupGetParentByChildId,
              data: this.treeRow,
            }).then((data) => {
              this.config[1].hidden = false
              this.formData.topName = data.name
            })
          }
        } else {
          if (this.isFirstGroup) {
            this.config[1].hidden = true
            this.formData.level = 'ONE'
          } else {
            this.config[1].hidden = false
            this.formData.topName = this.treeRow.name
            let level = 'ONE'
            switch (this.treeRow.level) {
              case 'ONE':
                level = 'TWO'
                break
              case 'TWO':
                level = 'THREE'
                break
              case 'THREE':
                level = 'FOUR'
                break
              case 'FOUR':
                level = 'FIVE'
                break
              default: // do something
            }
            this.formData.level = level
          }
        }
      })
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      const formData = this.$util.copyData(this.formData)
      switch (this.cmd) {
        case 'add':
          this.addMethod(formData)
          break
        case 'edit':
          this.editMethod(formData)
          break
        default: // do something
      }
    },
    // 新增
    addMethod(formData) {
      if (!this.isFirstGroup) formData.psn = this.treeRow.sn
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.userAccountGroupAdd,
        data: formData,
      }).then(() => {
        // lang:新增成功
        this.$element.showMsg(this.$t('base.addSuccess'), 'success')
        this.$emit('closePage', 'add')
      })
    },
    // 修改
    editMethod(formData) {
      const treeRow = this.$util.copyData(this.treeRow)
      Object.assign(treeRow, formData)
      console.log(treeRow)
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.userAccountGroupUpdate,
        data: treeRow,
      }).then(() => {
        // lang:修改成功
        this.$element.showMsg(this.$t('base.editSuccess'), 'success')
        this.$emit('closePage', 'edit')
      })
    },
  },
}
</script>